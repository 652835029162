@import "../../assets//scss/common.scss";

.container {
    padding-top: 57px;
    font-family: $amanin_font_family;

    &.mobile {

        &>div {
            // border: 2px solid red;

            &>div {
                // border: 10px solid blue;
                display: flex;
                flex-direction: column;
                padding: 10px;
                justify-content: center;
                align-items: center;

                &>div {
                    // border: 2px solid green;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &>span {}

                    &>img {
                        // border: 2px solid green;
                        margin: 0 auto;
                    }
                }
            }
        }
    }
}

.casestudy {
    // border:2px solid red;
    text-align: center;
    background-color: rgb(154, 136, 113);
    padding: 20px;

    &>img {
        width: 800px;

        @include responsiveMobile() {
            width: 100%;
        }
    }

    &.mobile {
        padding: 0px;
        padding-top: 20px;
        padding-bottom: 20px;

        &>img {
            width: 100%;
        }
    }

}