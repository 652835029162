@import "../assets/scss/common.scss";

.container {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    &>a {
        &>img {
            height: 40px;

            @include responsiveDesktopStandard() {
                height: 40px;
            }

            @include responsiveDesktopMedium() {
                height: 35px;
            }

            @include responsiveDesktopSmall() {
                height: 30px;
            }

            @include responsiveMobileStandard() {
                height: 40px;
            }

            @include responsiveMobileMedium() {
                height: 30px;
            }

            @include responsiveMobileSmall() {
                height: 20px;
            }
        }
    }

    &.mobile {}
}