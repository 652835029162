@import "../../assets/scss/common.scss";

.container {
    margin-top: 57px;
    display: flex;
    text-align: center;
    justify-content: center;
    background-color: cadetblue;
    flex-direction: column;
    font-family: $amanin_font_family;

    // border:2px solid red;

    &.mobile {}

    &>span {
        font-size: 1.8rem;
        font-weight: bold;
        color: rgb(244, 244, 244);
    }

    &>div {
        padding: 20px;
        overflow-y: auto;

        &>img {
            width: 800px;
        }

        &.mobile {
            &>img {
                width: 100%;
            }
        }
    }
}