@import "../../assets/scss/common.scss";

.container {
    padding-top: 57px;
    font-family: $amanin_font_family;
    // border:10px solid red;

    &.mobile {
        &>div {
            // border: 2px solid red;

            &>div {
                // border: 10px solid blue;
                display: flex;
                flex-direction: column;
                padding: 10px;
                justify-content: center;
                align-items: center;

                &>div {
                    // border: 2px solid green;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    // font-size: 1.5rem;

                    &>span {
                        // font-size: 1.5rem;
                    }
    
                    &>img {
                        // border: 2px solid green;
                        margin: 0 auto;
                    }
                }
            }
        }
    }
}