@import "../../assets/scss/common.scss";

.container {
    width: 100% !important;
    display: flex;
    margin-top: 57px;
}

.content {
    min-height: 500px;
    display: flex;
    // border: 10px solid blue;
    width: 100%;
    align-items: center;

    &.mobile {
        flex-direction: column;
    }

    &_left {
        display: flex;
        align-content: flex-start;
        justify-content: flex-end;
        height: 100%;
        flex-grow: 1;
        // border: 2px solid red;
        padding-right: 50px;
        padding-left: 20px;
        padding-top: 20px;
        padding-bottom: 20px;

        &.mobile {
            // border:2px solid red;
            align-items: center;
            justify-content: center;
            padding-right: 0px;
            padding-left: 0px;
        }

        &_c {
            justify-content: flex-end;
            align-items: center;
            display: flex;
            width: 100%;

            &.mobile {
                justify-content: center;
                align-items: center;
            }

            &>div {
                max-width: 700px;

                &.mobile {
                    max-width: 100% !important;
                    padding: 20px;
                }

                &>h1 {
                    padding-top: 0px;
                    max-width: 600px;
                    font-family: $amanin_font_family;


                    @include responsiveDesktopStandard() {
                        font-size: 3.0rem;
                    }

                    @include responsiveDesktopMedium() {
                        font-size: 2.4rem;

                    }

                    @include responsiveDesktopSmall() {
                        font-size: 1.8rem;
                    }

                    @include responsiveMobileStandard() {
                        font-size: 1.5rem !important;
                        font-weight: bold;
                    }

                    @include responsiveMobileMedium() {
                        font-size: 1.3rem !important;
                        font-weight: bold;
                    }

                    @include responsiveMobileSmall() {
                        font-size: 1.0rem !important;
                        font-weight: bold;
                    }

                    &.mobile {
                        max-width: 100% !important;
                        text-align: center;
                    }
                }

                &>p {
                    max-width: 550px;
                    border: 0px solid black;
                    // font-size: 1.1rem;
                    font-weight: 500;
                    margin-top: 20px;
                    min-height: 100px;

                    &.mobile {
                        // font-size: .75rem;
                        min-height: 50px;
                        // border:20px solid grey;
                        max-width: 100%;
                        justify-content: center;
                        text-align: center;
                    }
                }
            }
        }
    }

    &_right {
        background-color: $bgcolor_1;
        display: flex;
        align-content: flex-start;
        justify-content: flex-end;
        height: 100% !important;
        flex-grow: 1;
        // border:2px solid red;

        &.mobile {
            width: 100%;
            height: 100%;
        }

        &_c {
            justify-content: flex-start;
            align-items: center;
            display: flex;
            // border: 20px solid grey;
            flex-grow: 1;

            &.mobile {
                // align-items: flex-start;
                // border: 5px solid red;
                width: 100%;

                display: flex;
                justify-content: center;
            }
        }
    }
}

.imgslide {
    position: relative;
    // border: 2px solid green;
    width: 700px;
    height: 700px;

    @include responsiveDesktopStandard() {
        width: 700px;
        height: 700px;
    }

    @include responsiveDesktopMedium() {
        width: 550px;
        height: 550px;
    }

    @include responsiveDesktopSmall() {
        width: 400px;
        height: 400px;
    }

    &.mobile {
        width: 500px;
        height: 400px;
    }

    &>img {
        object-fit: cover;
    }

    & .img1 {
        // border:10px solid blue;
        position: absolute;
        top: 5%;
        left: -1%;

        height: 35%;
        width: 65%;
        border-radius: 30px;

        &.mobile {
            top: 1.2%;
            left: -0px;
        }
    }

    & .img2 {
        // border:10px solid blue;
        position: absolute;
        top: 43%;
        right: 13%;

        height: 50%;
        width: 45%;
        border-radius: 30px;

        &.mobile {
            top: 45%;
            right: 7%;
        }
    }

    & .img_h1 {
        // border: 2px solid blue;
        position: absolute;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
        border-radius: 7px;

        &.desktop {
            top: 37%;
            left: -0.5%;
            height: 5%;
        }

        &.mobile {
            top: 37%;
            left: -15%;
            // height: 7%;
            width: 86%;
            height: auto;
            border-radius: 3px;
        }
    }

    & .img_v1 {
        position: absolute;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.4);

        &.desktop {
            top: 14%;
            right: 5%;
            width: 6%;
            border-radius: 7px;
        }

        &.mobile {
            top: 8%;
            right: 0%;
            width: 8%;
            height: auto;
            border-radius: 3px;

        }
    }

    & .img_h2 {
        // border: 2px solid blue;
        position: absolute;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);

        &.desktop {
            top: 51%;
            left: -3%;
            height: 11%;
            border-radius: 7px;
        }

        &.mobile {
            top: 50%;
            left: -0%;
            width: 40%;
            height: auto;
            border-radius: 3px;

        }
    }

    & .img_v2 {
        // border: 2px solid blue;
        position: absolute;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.4);

        &.desktop {
            top: 1.2%;
            right: 0%;
            height: 39%;
            border-radius: 7px;
        }

        &.mobile {
            top: -2.3%;
            right: 0%;
            height: auto;
            width: 30%;
            border-radius: 3px;

        }
    }

    & .img_h3 {
        // border: 2px solid blue;
        position: absolute;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);

        &.desktop {
            top: 55%;
            left: -1.3%;
            height: 17%;
            border-radius: 7px;
        }

        &.mobile {
            top: 50%;
            left: -0%;
            width: 40%;
            height: auto;
            border-radius: 3px;
        }
    }

    & .img_v3 {
        // border: 20px solid blue;
        position: absolute;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.4);

        &.desktop {
            top: 31%;
            right: 0%;
            height: 4%;
        }

        &.mobile {
            top: 38%;
            left: 10%;
            width: 80%;
            height: auto;
        }
    }

    // & .img_h4 {
    //     border:2px solid blue;
    //     position: absolute;
    //     top: 55%;
    //     left: -1.3%;
    //     height: 9%;
    //     border-radius: 7px;
    //     box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);

    //     &.mobile {
    //         top: 50%;
    //         left: -7%;
    //         width: 50%;
    //         border-radius: 3px;
    //     }
    // }

    & .img_v4 {
        // border:2px solid blue;
        position: absolute;
        top: 14%;
        right: -1.2%;
        height: 11%;
        border-radius: 7px;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.4);

        &.mobile {
            top: 20%;
            left: 50%;
            width: 40%;
            height: auto;
        }
    }
}

.loading {
    max-width: 200px;

    &>div {
        border: 2px solid rgb(106, 139, 100);
        border-radius: 4px;
    }
}

.button {
    margin-top: 30px;
    // border:10px solid grey;

    &.mobile {
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &>button {
        border-radius: 50px;
        font-size: .95rem;
        font-weight: 500;
        margin-left: 10px;

        &.mobile {
            // font-size: .75rem;
            margin-left: 0px;
        }
    }

    &>div {
        // border:2px solid red;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 45px;
        margin-bottom: 5px;

        &.mobile {
            display: flex;
            align-items: center;
            justify-content: space-around;
            margin-top: 10px;

            &>img {
                width: 30px;

            }

            &>span {
                // font-size: .75rem;
            }
        }

        &>img {
            width: 50px;
            border-radius: 50px;
            margin-bottom: 5px;
            background-color: #545454;
            transition: .5s;

            &:hover {
                cursor: pointer;
                background-color: rgb(88, 44, 125);
                transition: .5s;
            }
        }

        &>div {
            font-size: .85rem;
            font-weight: 550;
            margin-top: 5px;
        }
    }
}

.mbt {
    padding-top: 50px;
    // font-size: 2.0rem !important;
    color: rgb(26, 107, 222);
}

.mb {
    // font-size: 1.7rem !important;
}

.mbb {
    // font-size: 1.5rem !important;
    margin-top: 30px;
    margin-bottom: 30px;
}

.imb {}

.opacity_0 {
    opacity: 0;
}