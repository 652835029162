@import "../../assets/scss/common.scss";

$color_font: rgb(94, 135, 216);
$bgcolor1: #8598b643;
$bgcolor2: rgba(255, 255, 255, 0.565);
$bgcolor3: rgba(255, 255, 255, 0.7);
$bgcolor4: #c0e5ee;

.container {
    margin-top: 57px;
    display: flex;
    flex-direction: column;
    background-color: $bgcolor1;
    font-family: $amanin_font_family;

    &.mcontainer {
        // margin-top: 88px;
        & .title3 {
            font-size: 1.5rem;
            text-align: justify;

            @include responsiveDesktopStandard() {
                font-size: 1.5rem;

            }

            @include responsiveDesktopMedium() {
                font-size: 1.3rem;

            }

            @include responsiveDesktopSmall() {
                font-size: 1.1rem;

            }

            @include responsiveMobileStandard() {
                font-size: 1.2rem;

            }

            @include responsiveMobileMedium() {
                font-size: 1.0rem;
            }

            @include responsiveMobileSmall() {
                font-size: .8rem;
            }
        }
    }

    &_c {
        margin: 0 auto;
        display: flex;
        min-height: calc(100vh - 88px);
        // border: 2px solid red;
        max-width: 1360px;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;

        & .title1 {
            font-size: 2.8rem;
            font-weight: 700;
            color: $color_font;

            @include responsiveDesktopStandard() {
                font-size: 2.8rem;

            }

            @include responsiveDesktopMedium() {
                font-size: 2.6rem;

            }

            @include responsiveDesktopSmall() {
                font-size: 2.4rem;

            }

            @include responsiveMobileStandard() {
                font-size: 2.4rem;

            }

            @include responsiveMobileMedium() {
                font-size: 2.2rem;
            }

            @include responsiveMobileSmall() {
                font-size: 2.0rem;
            }
        }

        & .title2 {
            font-size: 2.5rem;
            font-weight: 500;
            color: rgba($color: $color_font, $alpha: .81);
            text-align: center;

            @include responsiveDesktopStandard() {
                font-size: 2.5rem;

            }

            @include responsiveDesktopMedium() {
                font-size: 2.3rem;

            }

            @include responsiveDesktopSmall() {
                font-size: 2.1rem;

            }

            @include responsiveMobileStandard() {
                font-size: 1.7rem;

            }

            @include responsiveMobileMedium() {
                font-size: 1.5rem;
            }

            @include responsiveMobileSmall() {
                font-size: 1.3rem;
            }
        }

        & .title3 {
            font-size: 1.0rem;
            color: rgb(82, 76, 76);
            max-width: 1000px;
            padding-left: 10px;
            padding-right: 10px;
            text-align: justify;
            // border:2px solid red;
        }

        & .img {
            // background-color: $bgcolor2;
            background-color: white;
            border-radius: 250px;
            width: 250px;
            height: 250px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        & .qna {
            display: flex;
            // border:1px solid red;
            flex-wrap: wrap;
            justify-content: center;
            align-items: flex-start;
        }
    }
}

.faq {
    // border:4px solid red;
    background-color: rgba(255, 255, 255, 0.95);
    width: 600px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 1px 1px 10px rgba($color: $color_font, $alpha: .5);
    margin: 10px;
    font-size: 1.0rem;

    &.mobile {
        width: 90%;
    }

    &_title {
        // border: 1px solid red;
        margin: 0px auto;
        text-align: center;
        font-weight: bold;
        font-size: 1.2rem;
        color: #8598b6;

        @include responsiveDesktopStandard() {
            font-size: 1.2rem;

        }

        @include responsiveDesktopMedium() {
            font-size: 1.1rem;

        }

        @include responsiveDesktopSmall() {
            font-size: 1.0rem;

        }

        @include responsiveMobileStandard() {
            font-size: 1.2rem;

        }

        @include responsiveMobileMedium() {
            font-size: 1.1rem;
        }

        @include responsiveMobileSmall() {
            font-size: 1.0rem;
        }
    }

    &_content {
        border-bottom: 1px solid rgba(127, 170, 255, 0.45);
        margin-bottom: 10px;
        padding-bottom: 10px;
        transition: .5s;

        &:hover {
            cursor: pointer;
            background-color: rgba($color: $color_font, $alpha: .12);
            border-radius: 20px 5px 5px 5px;
            transition: .5s;
        }

        &>div {
            font-size: .9rem;
            color: $color_font;
            // border: 1px solid red;
            padding-right: 5px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-weight: bold;

            &.title {
                // border:2px solid red;

                &>span {
                    // border:1px solid blue;
                }
            }

            &.mobile {
                // font-size: 1.5rem;
            }

            & .number {
                display: flex;
                // background-color: $bgcolor4;
                min-width: 40px;
                height: 30px;
                border-radius: 40px;
                text-align: center;
                align-items: center;
                justify-content: center;
                font-weight: bold;
                margin-right: 10px;
            }

            &.answer {
                // border:1px solid red;
                color: black;
                font-size: .95rem;
                font-weight: normal;
                margin-top: 5px;
                padding-left: 50px;
                color: #6b6f78;
                // white-space: pre;

                &.mobile {
                    font-size: .90rem;
                }
            }
        }
    }
}

.options {
    margin-top: 10px;
    display: flex;

    &.mobile {
        // font-size: 1.5rem;
    }

    &>span {
        background-color: white;
        box-shadow: 1px 1px 10px rgba($color: $color_font, $alpha: .5);
        color: $color_font;
        font-weight: 500;
        padding: 8px;
        border-radius: 15px 15px 0px 0px;
        z-index: 100;
        transition: .5s;
        font-size: .9rem;

        &:hover {
            cursor: pointer;
            background-color: #f0f4f5;
            transition: .5s;
        }

        &.chosen {
            background-color: rgba($color: rgb(203, 240, 236), $alpha: 1);
            z-index: 101 !important;
        }
    }
}

.smiley {
    font-size: 34px;
    /* Ukuran font emoticon */
    display: inline-block;
    /* Agar emoticon tampil dalam satu baris dengan teks lainnya */
}

/* Menggunakan karakter Unicode untuk senyuman */
.smiley::before {
    content: "\1F60A";
    /* Kode Unicode untuk emoticon senyum */
}