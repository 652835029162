@import "../assets/scss/common.scss";


.containerb {
    font-family: $amanin_font_family;

}

.container {
    position: fixed !important;
    z-index: 201;
    top: 0px !important;
    width: 100%;
    font-family: $amanin_font_family;
    display: flex;
    justify-content: center;
    background-color: $bgcolor_1;
    color: $amanin_logo_color2;
    border-bottom: 1px solid rgba($color: $amanin_logo_color, $alpha: .5);
    
    height: 57px;    

    &_content {
        display: flex;
        flex-direction: row !important;
        // border:2px solid grey;
        width: 90%;
        max-width: 1400px;

        &.mobile {
            // border: 2px solid red;
        }

        &>div {
            display: flex;
            align-items: center;
            // border: 1px solid black;

            &.brand {
                margin-left: 10px;
                margin-right: 10px;
                // border:2px solid blue;
            }

            &.options {
                display: flex;
                // border:2px solid red;

                &>div {
                    transition: .3s;

                    &:hover {
                        transition: .3s;
                        cursor: pointer;
                    }
                }

                &.right {
                    // border:2px solid red;
                    flex-grow: 1;
                    justify-content: flex-end;
                }
            }
        }
    }
}

.item {
    display: inline-flex;
    border: 1px solid $bgcolor_1;
    padding: 3px;
    border-radius: 5px;
    margin-left: 10px;
    white-space: nowrap;
    transition: .3s;

    @include responsiveDesktopStandard() {
        font-size: 1.0rem;
        margin-left: 10px;
    }

    @include responsiveDesktopMedium() {
        font-size: .9rem;
        margin-left: 8px;
    }

    @include responsiveDesktopSmall() {
        font-size: .8rem;
        margin-left: 5px;
    }

    &:hover {
        transition: .3s;
        border: 1px solid rgba($color: $amanin_logo_color, $alpha: 1);
        box-shadow: 1px 1px 5px rgba($color: $amanin_logo_color, $alpha: .5);
        cursor: pointer;
    }
    &>a {
        color: rgba($color: $amanin_logo_color2, $alpha: 1);
        text-decoration: none;
    }
}

.menu {
    position: fixed;
    border:1px solid rgba($color: $amanin_logo_color, $alpha: .5);
    border-top: 0px;
    z-index: 1201;
    // width: 100%;
    padding: 10px 50px 10px 10px;
    top: 57px;
    left: 0px;
    background-color: rgba($color: $bgcolor_1, $alpha: 1);
    border-radius: 0 0 10px 10px;
    box-shadow: 1px 3px 5px rgba($color: $amanin_logo_color, $alpha: .5);
    transition: transform .5s;
    transform-origin: top center;
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;

    @include responsiveMobile() {
        font-size: .9rem;
    }

    &.open {
        // height: 150px;
        transform: scaleY(1);
    }

    &.close {
        // height: 0px;
        transform: scaleY(0);
    }

    // &_text {
    //     transition: transform 1s;
    //     transform-origin: top center;
    
    //     &.open {
    //         transform: scaleY(1);
    //     }
    
    //     &.close {
    //         transform: scaleY(0);
    //     }
    
    // }
}