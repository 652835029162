.container {
    &>div {
        display: flex;
        flex-direction: row;
        // border:2px solid white;
    
        & .package {
            background-color: rgb(154, 211, 166);
            color: black;
            padding:10px;
            margin:10px;
            transition: .5s;
            border-radius: 5px;
            transition: .5s;

            &:hover {
                cursor: pointer;
                transition: .5s;
                font-weight: bold;
            }
        }
        & .free {
            background-color: black;
            color: white;
            padding:10px;
            margin:10px;     
            border-radius: 5px;
            transition: .5s;
            &:hover {
                cursor: pointer;
                transition: .5s;
                font-weight: bold;
            }
       
        }
    }
}