@import "../../assets/scss/common.scss";

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.container_comimage {
    margin: 0px auto;
    max-width: 1024px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    &>div {
        width: 150px;
        height: 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        font-size: .85rem;
        margin-top: 10px;
        // border:1px solid rgba(0, 0, 0, 0.1);
        // box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.05);
        &>img {
            width: 100px;
        }
        &>span {
            text-align: center;
        }
    }
}

.container {
    background-color: rgba($color: $amanin_logo_color2, $alpha: 0.2);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    // height: calc(100vh - 67px);
    min-height: 650px;

    font-size: 1.0rem;

    &.mobile {
        align-items: center !important;
        justify-content: flex-start !important;
        // border: 10px solid green;
        height: auto;
    }

    &_bg {
        position: absolute;
        display: flex;
        align-items: center;
        width: 100%;
        left: 0px;
        height: calc(100%);

        z-index: 0;

        &_color {
            width: 100%;
            height: 400px;
            // background-color: rgb(146, 225, 154);
            // background: linear-gradient(to bottom, #7ed56f, #7ed56f9c);
        }
    }

    .bg2 {
        z-index: 101;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;

        &_modal {
            width: 700px;
            min-height: 500px;
            background-color: white;
            border-radius: 10px;
            box-shadow: 1px 3px 10px black;
            display: flex;
            padding: 10px;

            flex-direction: column;
            justify-content: center;
            align-items: center;

            &>span {
                font-weight: bold;
            }

            &>div {
                display: flex;
                margin-top: 10px;

                &>div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;

                    flex-direction: column;

                    &>span {
                        min-width: 200px;
                        text-align: center;
                        // font-size: .85rem;
                        font-weight: bold;
                        margin-top: 5px;

                        &.title {
                            font-weight: normal;
                        }
                    }
                }

                &>pre {
                    margin-left: 10px;
                    margin-right: 10px;
                    white-space: pre-wrap;
                    // font-size: .70rem;
                    max-height: 300px;
                    background-color: rgb(73, 85, 88);
                    color: white;
                    padding: 10px 10px 10px 10px;
                }
            }
        }
    }
}

.content {
    position: relative;

    max-width: 1160px;

    &.mobile {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        // border: 10px solid blue;
    }

    &_direction_l {
        position: absolute;
        padding: 10px;
        z-index: 101;
        width: 50px;
        height: 50px;
        border-radius: 100px;
        background-color: rgba(255, 255, 255, 0.9);
        display: flex;
        align-items: center;
        justify-content: center;
        top: 250px;
        left: 0;
        background-color: rgba(128, 128, 128, 0.9);
        font-weight: bold;
        color: white;
        transition: .5s;

        &:hover {
            background-color: white;
            transition: .5s;
            cursor: pointer;
            color: black;
        }
    }

    &_direction_r {
        position: absolute;
        padding: 10px;
        z-index: 101;
        width: 50px;
        height: 50px;
        border-radius: 100px;
        background-color: rgba(128, 128, 128, 0.9);
        font-weight: bold;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 250px;
        right: 0;
        transition: .5s;

        &:hover {
            background-color: white;
            transition: .5s;
            cursor: pointer;
            color: black;
        }
    }

    &_c {
        position: relative;
        z-index: 100;
        display: flex;
        align-items: flex-start;
        overflow-x: auto;
        scroll-behavior: smooth;
        // border: 10px solid red;
        padding: 20px;

        &.mobile {
            // height: 650px;
        }
    }
}

.story {
    // display: inline-flex;
    z-index: 1;
    flex-direction: column;
    min-width: 350px;
    max-width: 350px;
    border-radius: 10px 10px 0 0;
    margin-left: 10px;
    margin-right: 10px;
    border: 1px solid rgba($color: $amanin_logo_color, $alpha: 1);
    box-shadow: 1px 1px 10px rgba($color: $amanin_logo_color, $alpha: 0.5);

    &.mobile {
        min-width: 300px !important;
        max-width: 300px;
        // border: 10px solid red;
    }

    &_title {
        height: 120px;
        background-color: rgba($color: $amanin_logo_color, $alpha: 1);
        // background-color: rgba(188, 227, 191, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 5px;
        border-radius: 9px 9px 0px 0px;
        // border:2px solid red;
        text-align: center;

        &>span {
            font-weight: bold;
            padding: 5px;
            color: rgb(64, 59, 53);
        }

        &>img {
            height: 80px;
            margin-top: 10px;
            background-color: rgb(255, 255, 255);
            padding: 5px;
            border-radius: 10px;
            // border: 2px solid red;

            @include responsiveMobileStandard() {
                height: 60px;
            }

            @include responsiveMobileMedium() {
                height: 60px;

            }

            @include responsiveMobileSmall() {
                height: 60px;

            }
        }
    }

    &_content {
        display: flex;
        flex-direction: column;
        background-color: white;
        // padding: 20px;
        height: 100%;

        & .complete {
            text-align: end;
            // font-size: .65rem;
            font-style: italic;
            color: brown;

            &:hover {
                cursor: pointer;
                font-weight: bold;
                transition: .2s;
            }
        }

        &>pre {
            white-space: pre-wrap;
            background-color: white;
            height: 350px;
            margin-bottom: 0px;
            font-family: $amanin_font_family;
            text-align: justify;
            padding: 20px;
        }

        &_by {
            padding: 10px;
            background-color: white;
            display: flex;
            border-top: 2px solid rgba($color: $amanin_logo_color, $alpha: 0.5);
            min-height: 90px;
            align-items: center;
            justify-content: center;


            &>img {
                height: 80px;
                margin: 4px;
            }

            &>div {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                &>span {
                    text-align: center;
                    // font-size: .95rem;
                    font-weight: bold;

                    &.title {
                        font-weight: normal;
                        font-style: italic;
                        // font-size: .90rem;
                    }
                }
            }
        }
    }
}

.mb {
    // font-size: 1.2rem !important;
}

.mbpre {
    // font-size: 1.4rem !important;
}