@import "../../assets/scss/common.scss";

.container {
    // border:10px solid red;
    min-height: 300px;
    background-color: rgb(37, 37, 37);
    display: flex;

    &_c {
        // border:10px solid green;
        width: 100%;
        max-width: 1400px;
        margin: 0 auto;
        overflow-x: auto;
        display: flex;
        align-items: center;

        & .video {
            // width: 100px;
            border:1px solid rgba(135, 134, 134, 0.406);
            background-color: rgba($color: white, $alpha: 0.1);
            border-radius: 5px;
            padding: 10px;
            // height: 100px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
            margin-right: 10px;
            color: white;

            &>iframe {
                // width: 50px;
                height: 200px;
                border:1px solid black;
            }
            &>span {
                // border:1px solid red;
                font-size: .85rem;
                margin-top: 5px;
            }
        }
    }
}