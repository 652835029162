@import "../../assets/scss/common.scss";

// $color_sidebg: #0592a1;
$color_original: #019fef;
$color_sidebg: #2e97cb;

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.item2 {
    width: 90% !important;
}

.item {
    // border:1px solid blue;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 40%;
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: .9rem;

    @include responsiveDesktopStandard() {
        font-size: .9rem;
    }

    @include responsiveDesktopMedium() {
        font-size: .8rem;
    }

    @include responsiveDesktopSmall() {
        font-size: .7rem;
    }

    @include responsiveMobileStandard() {
        font-size: 1.0rem;
    }

    @include responsiveMobileMedium() {
        font-size: .8rem;
    }

    @include responsiveMobileSmall() {
        font-size: .6rem;
    }


    &.mb {
        // width: 300px !important;
        width: 40%;
        // border:2px solid blue;
        text-align: left;
    }

    &.mb2 {
        width: 700px !important;
        // border:2px solid blue;
        text-align: left;
    }

    &>img {
        padding: 5px;
        width: 30px;
    }

    &_dot {

        // width: 45px !important;
        height: 45px;
        // border:1px solid red;
        display: flex;
        align-items: center;
        margin-right: 8px;

        &>div {
            width: 15px;
            height: 15px;
            border-radius: 15px;
            background-color: $amanin_logo_color2;
            margin-bottom: 4px;
        }
    }

    &>span {
        // font-size: 1.05rem;
    }
}

.container {
    height: calc(100vh - 57px);
    max-height: 800px;
    min-height: 600px;
    display: flex;
    font-size: 1.1rem;

    @include responsiveDesktopStandard() {
        font-size: 1.1rem;
    }

    @include responsiveDesktopMedium() {
        font-size: .9rem;
    }

    @include responsiveDesktopSmall() {
        font-size: .7rem;
    }

    @include responsiveMobileStandard() {
        font-size: 1.1rem;
    }

    @include responsiveMobileMedium() {
        font-size: .9rem;
    }

    @include responsiveMobileSmall() {
        font-size: .7rem;
    }

    &_left {
        // border:10px solid blue;
        background-color: $color_sidebg;
        display: flex;
        align-content: flex-end;
        justify-content: flex-end;
        height: 100%;
        min-width: 35%;
        padding: 20px;

        &_c {
            // display: flex;
            // flex-direction: column;
            // border:2px solid red;
            max-width: 400px;

            &_2 {
                // border:2px solid red;
            }
        }
    }

    &_right {
        // border:2px solid green;
        flex-grow: 1;
        background-color: #f7f8fa;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.body2 {

    // border: 10px solid red;
    &_brand {
        background-color: rgba(0, 0, 0, 0.6);
        padding: 10px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.mobile {
            margin: 5px;
        }

        &_desc {
            color: white;
            font-weight: bold;
            margin-top: 10px;
            font-size: 1.5rem;
            text-align: start;
            margin-top: 10px;
            margin-bottom: 10px;

            @include responsiveDesktopStandard() {
                font-size: 1.5rem;
            }

            @include responsiveDesktopMedium() {
                font-size: 1.3rem;
            }

            @include responsiveDesktopSmall() {
                font-size: 1.1rem;
            }

            @include responsiveMobileStandard() {
                font-size: 1.0rem;
            }

            @include responsiveMobileMedium() {
                font-size: .9rem;
            }

            @include responsiveMobileSmall() {
                font-size: .7rem;
            }

            &.mobile {
                // border:2px solid yellow;
                margin-top: 0px;
                text-align: center;
            }
        }

        &_prod {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            // border:10px solid red;
            flex-wrap: wrap;

            &>div {
                margin: 20px 5px 20px 5px;
                border: 2px solid white;
                padding: 2px;
                border-radius: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: .5s;

                &.mobile {
                    margin: 5px 5px 5px 5px !important;
                }

                &>span {
                    background-color: rgb(90, 129, 161);
                    margin-left: 10px;
                    margin-right: 10px;
                    padding-left: 10px;
                    padding-right: 10px;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    border-radius: 50px;
                    color: white;
                    font-weight: bold;

                    white-space: nowrap;
                }

                &:hover {
                    cursor: pointer;
                    background-color: rgba(255, 255, 255, 0.8);
                    transition: .5s;
                }
            }
        }
    }

    &_content {
        // border: 10px solid cyan;
        height: 100%;

        animation: fadeIn 2s;
        display: flex;
        align-items: center;
        justify-content: space-around;

        &.mobile {
            margin: 0 auto;
            // border:10px solid grey;
            flex-direction: column;
            width: 90%;
            margin-top: 10px;

        }

        &>img {
            // border:10px solid green;
            border-radius: 30px;
            margin: 3%;
            width: 40%;
        }

        &>div {
            max-width: 520px;
            // margin-left: 50px;
            // border:10px solid blue;
            margin-right: 3%;

            &.mb {
                // width: 800px !important;
                width: 100% !important;
                margin-left: 0px;
                // border:10px solid green;

                &>h1 {
                    // border:10px solid black;
                    text-align: center;
                }

                &>p {
                    text-align: center;
                    max-width: 100% !important;
                    // border:10px solid green;
                }

                &>div {
                    // border:10px solid green;
                    text-align: center;
                    justify-content: center;
                    align-items: center;
                }
            }

            &>h1 {
                font-weight: bold;
                // border:2px solid red;
                text-align: center;
                font-size: 1.9rem;

                @include responsiveDesktopStandard() {
                    font-size: 1.9rem;
                }

                @include responsiveDesktopMedium() {
                    font-size: 1.7rem;
                }

                @include responsiveDesktopSmall() {
                    font-size: 1.5rem;
                }

                @include responsiveMobileStandard() {
                    font-size: 1.3rem;
                }

                @include responsiveMobileMedium() {
                    font-size: 1.1rem;
                }

                @include responsiveMobileSmall() {
                    font-size: .9rem;
                }
            }

            &>h2 {
                font-weight: bold;
                margin-top: 20px;
                margin-bottom: 20px;
                // border:2px solid red;
            }

            &>p {
                // font-size: 1.3rem;
                // max-width: 380px;
                // border:2px solid red;
                text-align: center;
            }

            &>div {
                display: flex;
                flex-wrap: wrap;
            }
        }
    }
}

.learnmore {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 50px;
    font-weight: bold;

    &>a {
        text-decoration: none;
        font-size: 1.5rem;
        background-color: $color_sidebg;
        padding: 5px;
        color: white;
        border-radius: 3px;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.292);
        transition: .3s;

        @include responsiveDesktopStandard() {
            font-size: 1.5rem;
        }

        @include responsiveDesktopMedium() {
            font-size: 1.3rem;
        }

        @include responsiveDesktopSmall() {
            font-size: 1.1rem;
        }

        @include responsiveMobileStandard() {
            font-size: 1.1rem;
        }

        @include responsiveMobileMedium() {
            font-size: .9rem;
        }

        @include responsiveMobileSmall() {
            font-size: .7rem;
        }

        &:hover {
            transition: .3s;
            background-color: rgba($color: $color_original, $alpha: 0.9);
        }
    }
}

.mcontainer {

    // border: 10px solid red;
    @include responsiveMobileStandard() {
        font-size: 1.1rem;
    }

    @include responsiveMobileMedium() {
        font-size: .9rem;
    }

    @include responsiveMobileSmall() {
        font-size: .7rem;
    }

    &_top {
        display: flex;
        flex-direction: column;
        background-color: $color_sidebg;
        // border:5px solid green;

        &_t {
            display: flex;
            flex-direction: row;
            width: 100%;

            display: flex;
            justify-content: space-around;
            align-items: center;
            align-content: space-around;
            // height: 100px;

            &>span {
                margin-left: 10px;
                margin-right: 10px;
            }
        }

        &_b {
            display: flex;
            flex-direction: row;
            justify-content: space-around;

        }
    }

    &_bottom {
        background-color: #f7f8fa;
        // border:20px solid green;
        display: flex;

        &_content {
            // border:2px solid red;
        }
    }
}

.mobile {
    // font-size: 1.4rem !important;
}

.mobile2 {
    // font-size: 1.4rem !important;
}

.imgmobile {}

.chosen {
    background-color: white;
}