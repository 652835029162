@import '../../assets/scss/common.scss';

.container {
    // border: 10px solid red;
    width: 100%;
    margin-top: 57px;
    min-height: calc(100vh - 67px);
    display: flex;
    flex-direction: column;
    font-family: $amanin_font_family;
    // background-color: black;

    &_title {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.5rem;
        font-weight: bold;
        color: rgb(82, 82, 82);
        // border:2px solid red;
        padding: 10px;

        @include responsiveMobileStandard() {
            font-size: 1.2rem;

        }

        @include responsiveMobileMedium() {
            font-size: 1.2rem;

        }

        @include responsiveMobileSmall() {
            font-size: 1.2rem;

        }
    }

    &_plans {
        // border: 2px solid green;
        display: flex;
        align-items: flex-start;
        justify-content: space-evenly;
        // padding: 20px;
        max-width: 1360px;
        margin: auto;

        &.mobile {
            // border:10px solid red;
            justify-content: flex-start;
            overflow-x: auto;
            max-width: 100%;
        }
    }
}

.plan {
    width: 250px;
    min-width: 250px;
    min-height: 500px;
    box-shadow: 1px 1px 10px rgb(169, 168, 168);
    border-radius: 10px;
    padding: 10px;
    margin: 5px;

    &.mobile {
        width: 250px !important;
        min-width: 250px !important;
        font-size: 1.8rem !important;
        // border:2px solid red !important;
    }

    &_title {
        font-size: 1.2rem;
        font-weight: bold;
        color: cornflowerblue;
        white-space: nowrap;
        // border:1px solid red;
        margin-bottom: 1px;
    }

    &_what {
        display: flex;
        align-items: center;

        &>span {
            // border:1px solid red;
            font-size: .75rem;
            background-color: rgb(42, 116, 91);
            color: white;
            font-weight: bold;
            padding: 5px;
            border-radius: 3px;
            margin-top: 0px;
            transition: .5s;
            margin-right: 5px;

            &:hover {
                cursor: pointer;
                transition: .5s;
                background-color: rgb(240, 167, 219);
                color: rgb(64, 59, 59);
            }
        }
    }

    &_price {
        // font-size: 1.5rem;
        font-weight: bold;
        color: rgb(207, 94, 94);
        // border:1px solid red;
        display: flex;
        align-items: flex-start;
    }

    &_action {
        margin-top: 10px;
        margin-bottom: 10px;

        &>span {
            border: 1px solid rgba(101, 96, 96, 0.293);
            background-color: rgb(27, 79, 223);
            color: white;
            font-weight: bold;
            font-size: .85rem;
            border-radius: 30px;
            padding: 7px;
            transition: .5s;

            &.mobile {
                // font-size: 1.0rem;
            }

            &:hover {
                background-color: antiquewhite;
                cursor: pointer;
                transition: .5s;
                color: rgb(80, 79, 79);
            }
        }
    }

    &_features {
        &>div {
            &>span {
                display: flex;
                font-weight: bold;
                font-size: .75rem;
                // border:1px solid black;
                height: 20px;
                justify-content: flex-start;
                align-items: center;

                &.mobile {
                    // font-size: .75rem;
                }

                &>img {
                    width: 25px;
                }
            }


            &>div {
                &>ul {
                    font-size: .75rem;
                    // border:1px solid black;
                    padding-left: 28px !important;

                    &.mobile {
                        // font-size: .75rem;
                    }

                    &>li {}

                }
            }
        }
    }
}

.mcontainer {
    // border:2px solid red;
    margin-top: 88px;
}

.highlighted {
    font-weight: bolder;
    color: brown;
}

.highlightedblue {
    font-weight: bold;
    color: rgb(18, 126, 23);
}

.highlightedpink {
    font-weight: bold;
    color: rgb(206, 23, 223);
}

// Konfigurasi Ujian
// Mengonfigurasi ujian online yang dilaksanakan
// ✔ Broadcast Alert
// Mengirimkan pesan ke seluruh peserta ujian online
// ✔ Penandaan Peserta
// Notes, memberikan catatan khusus tentang seorang peserta ujian online
// Screenshot, menscreenshot tangkapan wajah peserta dan tangkapan layar peserta pada saat tertentu
// Flag, menandai peserta yang dianggap mencurigakan
// ✔ Penjadwalan Ujian
// Menjadwalkan waktu pelaksanaan ujian
// ✔ Deteksi Kecurangan Berbasis AI
// Mendeteksi dan mencatat kecurangan - kecurangan yang mungkin dilakukan peserta selama  ujian berlangsung 
// ✔ Dukungan secara langsung berbasis percakapan
// Peserta dapat mengirimkan permintaan bantuan 
// Peserta dan pengawas dapat saling mengirimkan pesan lewat chatbox
// ✔ Post Proktor
// Tempat untuk mengelola, mengatur, dan mengkonfigurasi ujian online yang diselenggarakan
// Melihat kembali pelaksanaan ujian yang telah dan sedang berlangsung