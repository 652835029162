@import "../assets/scss/common.scss";

.container {
    padding: 10px;
    background-color: rgb(37, 37, 41);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding-top: 30px;
    font-size: 1rem;

    @include responsiveDesktopStandard() {
        font-size: 1rem;
    }

    @include responsiveDesktopMedium() {
        font-size: .9rem;
    }

    @include responsiveDesktopSmall() {
        font-size: .8rem;
    }

    &_company {
        color: white;
        font-weight: 500;
        font-size: .7rem;

        @include responsiveDesktopStandard() {
            font-size: .7rem;
        }

        @include responsiveDesktopMedium() {
            font-size: .6rem;
        }

        @include responsiveDesktopSmall() {
            font-size: .5rem;
        }

    }

    &_lang {
        width: 100%;
        max-width: 1426px;
        display: flex;
        justify-content: flex-end;
        padding: 5px;
    }

    &_top {
        padding-bottom: 80px;
        margin-bottom: 10px;
        border-bottom: 1px solid rgb(69, 69, 69);

        // max-width: 1360px;
        max-width: 90%;
        // min-width: 1240px;
        min-width: 90%;
        // min-height: 100px;

        color: white;

        display: flex;
        justify-content: space-evenly;

        &.mb {
            min-width: 90% !important;
            flex-direction: column;
        }

        &>div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;


            &.mobile {
                border: 1px solid white;
                border-radius: 30px;
                // padding: 10px;
                padding-left: 20px;
                margin-bottom: 10px;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                transition: height .5s;

                &.open {
                    // height: 100px;
                    // justify-content: flex-start !important;
                }

                &.close {
                    // height: 40px;
                    // border:2px solid red;
                }
            }

            & .lang {
                border: 1px solid white;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 10px;
                padding: 10px;
                border-radius: 10px;
                font-weight: 500;
            }

            &>span,
            &>a {
                padding-top: 20px;

                @include responsiveMobile() {
                    padding-top: 0px;
                }

                &.title {
                    padding-top: 0px;
                    font-weight: bold;

                    &.mobile {
                        display: flex;
                        align-items: center;
                        transition: .5s;
                        // border:1px solid red;
                        height: 40px;

                        &:hover {
                            cursor: pointer;
                            color: rgb(205, 208, 171);
                            transition: .3s;
                        }
                    }
                }

                &.item {
                    text-decoration: none;
                    color: white;
                    transition: .3s;
                    height: 40px;
                    font-size: .9rem;

                    @include responsiveDesktopStandard() {
                        font-size: .9rem;
                    }

                    @include responsiveDesktopMedium() {
                        font-size: .8rem;
                    }

                    @include responsiveDesktopSmall() {
                        font-size: .7rem;
                    }


                    &:hover {
                        cursor: pointer;
                        transition: .3s;
                        color: rgb(227, 196, 109);
                    }
                }
            }
        }
    }
}

.triangle_up {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid white;
    /* Change the color as needed */
    margin-left: 10px;
}

.triangle_down {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid white;
    /* Change the color as needed */
    margin-left: 10px;
}