@import "../../assets/scss/common.scss";


.container {
    margin-top: 57px;
    background-color: #f7f8fa;
    display: flex;
    flex-direction: column;
    font-family: $amanin_font_family;
    font-size: 1.0rem;
    min-height: 10px;

    &.mobile {
        margin-top: 57px;
    }

    &_title {
        text-align: center;
        // font-size: 1.5rem;
        font-weight: bold;
        margin-top: 20px;
    }

    &_content {
        // border: 10px solid red;
        // height: 100%;
        display: flex;
        align-items: center;
        padding: 10px;

        @include responsiveMobile() {
            padding: 0px;
        }

        &>div {
            margin: auto;
            // border: 2px solid red;

            max-width: 1360px;
            display: flex;
            flex-direction: row;

            &.mobile {
                flex-direction: column !important;
            }

            & .left {
                display: flex;
                // border: 2px solid blue;
                max-width: 500px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                @include responsiveMobile() {
                    max-width: 100%;
                    padding: 10px;
                }

                & .title {
                    font-size: 2.5rem;
                    font-weight: bold;
                    margin-bottom: 10px;

                    @include responsiveDesktopStandard() {
                        font-size: 2.5rem;    
                    }

                    @include responsiveDesktopMedium() {
                        font-size: 2.3rem;
                    }

                    @include responsiveDesktopSmall() {
                        font-size: 2.1rem;
                    }

                    @include responsiveMobileStandard() {
                        font-size: 2.1rem;
                    
                    }

                    @include responsiveMobileMedium() {
                        font-size: 1.9rem;
                    
                    }

                    @include responsiveMobileSmall() {
                        font-size: 1.7rem;
                    
                    }
                }

                & .info {
                    // font-size: 1.1rem;
                    &>a {
                        clear: both;
                        border:1px solid rgba($color: $amanin_logo_color, $alpha: 1);
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        background-color: rgba($color: $amanin_logo_color, $alpha: .2);
                        border-radius: 10px;
                        &>img {
                            margin: 10px;
                            height: 30px;
                        }
                    }
                }

                &>img {
                    border-radius: 10px;
                    width: 300px;
                    margin: 10px;
                    margin-top: 20px;
                    
                    @include responsiveMobile() {
                        margin: 0 auto;
                    }
                }
            }

            & .contact {
                // border:2px solid black;
                min-width: 500px;
                padding: 10px;

                @include responsiveMobile() {
                    min-width: 100%;
                    max-width: 100%;
                    margin: 0 auto;
                }
            }
        }
    }
}

.formulir {
    margin-top: 30px;
    margin-bottom: 30px;
    // border: 1px solid #00000028;
    min-width: 500px;
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);

    @include responsiveMobile() {
        min-width: 0 !important;
        max-width: 500px;
        padding: 10px;
        margin: 0 auto;
    }

    & .title {
        // border:1px solid red;
        margin: auto;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        margin: 10px;
        font-weight: bold;
        // font-size: 1.5rem;
        color: rgb(93, 75, 53);
    }

    & .email {
        &>input[type="text"] {
            border: 0px;
            background-color: #dfdfdf;
            border-radius: 5px;
            margin: 5px;
            padding: 5px;
            margin-left: 10px;

            &:focus {
                border: 0px solid blue;
                outline: none;
            }
        }
    }

    & .info {
        // border:2px solid red;
        display: flex;
        flex-direction: column;

        &>textarea {
            background-color: #dfdfdf;
            border: 0px;
            border-radius: 5px;
            padding: 5px;
            margin-top: 5px;

            &:focus {
                outline: none;
            }
        }
    }

    & .button {
        // border:2px solid red;
        padding: 20px;
        display: flex;
        align-items: center;

        &>button {
            margin: auto;
        }
    }

}