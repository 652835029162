@import "../../assets/scss/common.scss";

.container {
    margin-top: 57px;
    font-size: .85rem;
    font-family: $amanin_font_family;

    &_content {
        max-width: 1366px;
        margin: 0 auto;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: space-around;

        & .article {
            width: 300px;
            font-size: .75rem;
            box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.35);
            margin: 5px;
            float: left;
            border-radius: 5px;

            &>img {
                height: 200px;
                width: 300px;
                object-fit: cover;
                border-radius: 5px 5px 0px 0px;
            }

            &>a {
                font-size: 1.0rem;
                font-weight: bold;
                padding: 10px;
                display: flex;
                text-align: center;
                text-decoration: none;
                transition: .3s;
                // border:1px solid red;
                // height: 70px;
                &:hover {
                    transition: .3s;
                    cursor: pointer;
                    color: $amanin_logo_color2;
                }
            }

            &>div {
                padding: 0px 10px 10px 10px;
                // border:4px solid blue;
                word-wrap: break-word;
                text-align: justify;
            }
        }

        & .articlefull {
            // border: 2px solid red;
            padding: 10px;
            display: flex;
            flex-direction: column;

            &>span {
                margin: 0 auto;
                // border: 1px solid blue;
                font-size: .9rem;
                font-weight: bold;
                margin-bottom: 8px;
                text-align: center;
            }

            &>div {
                max-width: 1300px;

                &>article {
                    text-align: justify;
                    padding: 10px;
                    &>p {
                        text-indent: 1rem;
                    }

                    &>figure {
                        float: left;
                        &>img {
                            // display: none;
                            // border: 2px solid green;
                        margin: 10px 20px 0px 10px;

                            @include responsiveMobile() {
                                width: 100%;
                                margin: 0px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.footer {
    font-style: italic;
    font-size: .55rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    &>span {
        white-space: nowrap;
        margin: 10px;
        font-weight: bold;
        color: $amanin_logo_color2;
    }
}