@import "../../assets/scss/common.scss";

.container {
    margin-top: 57px;
    // border:1px solid red;
    font-family: $amanin_font_family;
    font-size: 1.0rem;
    background-color: rgba($color: $amanin_logo_color3, $alpha: 0.3);

    &_content {
        max-width: 1300px;
        margin: 0 auto;
        // border: 2px solid red;
        min-height: 500px;


        &>span {
            font-size: 1.2rem;
            font-weight: bold;
            text-align: center;
            // border:1px solid blue;
            width: 100%;
            display: flex;
            justify-content: center;
            padding-top: 10px;
            color: #6e6e6e;
        }

        &>div {
            & .manual {
                display: flex;
                // border: 1px solid black;
                margin: 10px;

                & .title {
                    font-weight: bold;
                    color: rgba($color: $amanin_logo_color2, $alpha: 1);

                    &>a {
                        width: 100% !important;
                        white-space: wrap;
                        // border:1px solid red;
                        // word-break: break-all;
                        // overflow-wrap: break-word;
                        // display: block;
                    }
                }

                & .link {
                    &>a {
                        font-family: 'OpenSans';
                    }
                }
            }

        }
    }
}