@import "../assets/scss/common.scss";

.container {
    border: 1px solid $amanin_logo_color2;
    width: 45px;
    height: 45px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    // justify-content: center;
    align-items: center;

    &>div {
        border: 2px solid $amanin_logo_color2;
        border-radius: 2px;
        margin: 1px;
        transition: width .5s;

        &.close {
            width: 0px;
        }

        &.open {
            width: 30px;

        }
    }
}

.x {
    font-weight: 100;
    font-size: 1.9rem;
    display: inline-flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 40px;
    position: relative;
    border: 0px solid transparent !important;

    &_1 {
        position: absolute;
        border-left: 2px solid $amanin_logo_color2;
        height: 80%;
        transform: rotate(45deg);
    }

    &_2 {
        position: absolute;
        border-left: 2px solid $amanin_logo_color2;
        height: 80%;
        transform: rotate(-45deg);
    }
}

.hclose {
    height: 0px;
}

.hopen {
    height: 80%;

}