@import "../assets//scss/common.scss";

.container {
    display: inline-flex;
    // border:1px solid red;

    background-color: rgb(15, 114, 235);
    border: 1px solid white;
    border-radius: 5px;
    margin-top: 10px;

    transition: .2s;

    &:hover {
        cursor: pointer;
        background-color: rgba(23, 73, 225, 0.8);
        transition: .2s;
    }

    &>a {
        color: white;
        text-decoration: none;
        padding: 5px;
        font-weight: 500;

        @include responsiveDesktopStandard() {
            font-size: 1.1rem;
        }

        @include responsiveDesktopMedium() {
            font-size: .9rem;
        }

        @include responsiveDesktopSmall() {
            font-size: .7rem;
        }
    }
}