@import "../../assets/scss/common.scss";

.container {
    // border:2px solid red;
    font-family: $amanin_font_family;
    font-size: .8rem;

    &_top {
        min-height: 100px;
        // border:2px solid red;
        max-width: 1466px;
        margin: 0 auto;
        margin-top: 57px;
        display: flex;
        overflow-x: auto;

        &>div {
            border:1px solid rgba(0, 0, 0, 0.194);
            box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.385);
            min-width: 200px;
            max-width: 300px;
            text-align: center;
            align-items: center;
            display: flex;
            margin: 5px;
            border-radius: 5px;

            padding: 5px;
            transition: .3s;

            &:hover {
                cursor: pointer;
                border-color: rgba(0, 0, 0, 0.5);
                transition: .3s;
            }

            &.chosen {
                transition: .3s;
                background-color: rgb(161, 231, 236);
            }

            &>img {
                margin-right: 3px;
            }
        }
    }

    &_bottom {
        // border: 2px solid red;
        max-width: 1466px;
        margin: 0 auto;
        margin-bottom: 5px;

        &>div {
            width: 100%;
            overflow-x: auto;

            &>img {
                // border:2px solid red;
                max-height: calc(120vh - 0px);
            }
        }
    }
}