@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins Regular'), local('Poppins-Regular'), url("../fonts/Poppins/Poppins-Regular.ttf") format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    src: local('Poppins Bold'), local('Poppins-Bold'), url("../fonts/Poppins/Poppins-Bold.ttf") format('truetype');
}

//Roboto
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    src: local('Roboto Regular'), local('Roboto-Regular'), url("../fonts/Roboto/Roboto-Regular.ttf") format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    src: local('Roboto Bold'), local('Roboto-Bold'), url("../fonts/Roboto/Roboto-Bold.ttf") format('truetype');
}

//Open Sans
@font-face {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: normal;
    src: local('OpenSans Regular'), local('OpenSans-Regular'), url("../fonts/OpenSans/OpenSans-VariableFont_wdth,wght.ttf") format('truetype');
}

@font-face {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: bold;
    src: local('OpenSans Bold'), local('OpenSans-Bold'), url("../fonts/OpenSans/OpenSans-VariableFont_wdth,wght.ttf") format('truetype');
}

//Quicksand
// @font-face {
//     font-family: 'Quicksand';
//     font-style: normal;
//     font-weight: normal;
//     src: local('OpenSans VariableFont_wght'), local('OpenSans-VariableFont_wght'), url("../fonts/Quicksand/Quicksand-VariableFont_wght.ttf") format('truetype');
// }

// @font-face {
//     font-family: 'Quicksand';
//     font-style: normal;
//     font-weight: normal;
//     src: local('Quicksand Regular'), local('Quicksand-Regular'), url("../fonts/Quicksand/static/Quicksand-Regular.ttf") format('truetype');
// }

@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: normal;
    src: local('Quicksand Semi Bold'), local('Quicksand-SemiBold'), url("../fonts/Quicksand/static/Quicksand-SemiBold.ttf") format('truetype');
}

//Mont
@font-face {
    font-family: 'Mont';
    font-style: normal;
    font-weight: normal;
    src: local('Mont Regular'), local('Mont-Regular'), url("../fonts/Mont/Mont-Regular.ttf") format('truetype');
}

@font-face {
    font-family: 'Mont';
    font-style: normal;
    font-weight: bold;
    src: local('Mont Bold'), local('Mont-Bold'), url("../fonts/Mont/Mont-Bold.ttf") format('truetype');
}
