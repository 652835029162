@import "../../assets/scss/common.scss";

.container {
    padding-top: 57px;
    font-family: $amanin_font_family;
    font-size: 1.5rem;
    color: white;
    background-image: url("../../assets/imgs/content/bgaboutus.jpg");
    background-size: cover;


    @include responsiveMobile() {
        font-size: 1.0rem;
    }


    &_c {
        // min-height: calc(100vh - 57px);
        padding-bottom: 57px;
        position: relative;

        & .title {
            font-size: 2.0rem;
            font-weight: 700;
            text-align: center;
            color: white;
            // border:2px solid red;
            max-width: 800px;
            padding: 50px;
            margin: 0 auto;

            @include responsiveMobile() {
                font-size: 1.4rem;
            }
        }

        & .location {
            max-width: 800px;
            margin: 0 auto;
            display: flex;
            align-items: flex-start;

            &>div {
                padding: 10px;
                border-radius: 10px;
                border:1px solid rgba($color: $amanin_logo_color2, $alpha: 0.3);
                background-color: rgba($color: $amanin_logo_color2, $alpha: 0.1);
                box-shadow: 1px 1px 10px rgba($color: $amanin_logo_color2, $alpha: 0.5);
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin: 10px;

                &>img {
                    margin: 0 auto;
                    height: 50px;
                    margin-bottom: 50px;

                    @include responsiveMobile() {
                        height: 40px;
                        margin-bottom: 40px;
                    }
                }

                &>span {
                    &.company {
                        font-weight: bold;
                        margin-bottom: 20px;
                    }
                }

                &>a {
                    // border:1px solid bla;
                    padding: 10px;
                    border-radius: 5px;
                    margin-top: 20px;
                    background-color: white;
                    color: rgba($color: $amanin_logo_color2, $alpha: 1);
                    font-weight: 510;


                }
            }

        }

        &_box {
            margin: 0 auto;
            // border:1px solid green;
            max-width: 1200px;
            min-height: 500px;
            background-color: white;
            border-radius: 10px;
            box-shadow: 1px 1px 10px rgba(201, 125, 125, 0.7);
            padding: 20px;
        }
    }
}

.background {
    position: absolute;
    // border:10px solid red;
    width: 100%;
    z-index: -10;

    &_top {
        min-height: 250px;
        background-color: rgb(224, 102, 104);
    }
}