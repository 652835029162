@import "../../assets/scss/common.scss";

.container {
    height: calc(100vh - 57px);
    max-height: 800px;
    min-height: 550px;
    display: flex;
    border-top: 2px solid rgba($color: $amanin_logo_color, $alpha: 0.3);
    border-bottom: 2px solid rgba($color: $amanin_logo_color, $alpha: 0.3);
    &_left {
        display: flex;
        align-content: flex-start;
        justify-content: flex-end;
        height: 100%;
        min-width: 50%;

        &_c {
            justify-content: flex-end;
            align-items: center;
            display: flex;
            margin-left: 30px;
        }
    }

    &_right {
        display: flex;
        align-items: center;

        &_c {
            justify-content: flex-start;
            align-items: center;
            display: flex;
            max-width: 590px;
        }
    }

}

.body3 {
    &_content {
        padding: 50px;
        max-width: 700px;

        &_title {
            font-weight: bold;

            @include responsiveDesktopStandard() {
                font-size: 2rem;
            }

            @include responsiveDesktopMedium() {
                font-size: 1.8rem;
            }

            @include responsiveDesktopSmall() {
                font-size: 1.6rem;
            }
        }

        &_content {
            @include responsiveDesktopStandard() {
                font-size: 1.2rem;
            }

            @include responsiveDesktopMedium() {
                font-size: 1.0rem;
            }

            @include responsiveDesktopSmall() {
                font-size: .8rem;
            }
        }
    }

    &_content2 {
        display: flex;
        flex-wrap: wrap;
        max-width: 600px;
        min-height: 500px;
        justify-content: space-around;
        align-items: center;
        // border:2px solid red;

        &>div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border: 2px solid $bgcolor_2;
            text-align: center;
            height: 150px;
            width: 40%;
            border-radius: 10px;
            font-weight: bold;

            @include responsiveDesktopStandard() {
                font-size: 1.2rem;
            }

            @include responsiveDesktopMedium() {
                font-size: 1.0rem;
            }

            @include responsiveDesktopSmall() {
                font-size: .8rem;
            }

            &:hover {
                transition: 1s;
                cursor: pointer;
                border-color: rgb(198, 124, 27);
            }
        }
    }
}

.chosen {
    left: 30px;
    width: 0px;
    height: 500px;
    background-color: $bgcolor_2;
    box-shadow: 1px 2px 20px rgba($color: black, $alpha: 0.5);
    display: flex;

    &_show {
        width: 900px;
        transition: width 1s;

        &>img {
            width: 500px;
            transition: width 1s;
        }

        &>div {
            transition: width 2s;
            transition-delay: 5s;
            width: auto;
        }
    }

    &_close {
        width: 0px;
        transition: width 1s;

        &>img {
            width: 0px;
            transition: width 1s;
        }

        &>div {
            width: 0px;

            &>div {
                font-size: 0px !important;
            }
        }
    }

    &>img {
        height: 500px;
        object-fit: cover;
    }

    &_c {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        // border: 10px solid black;

        &_title {
            font-weight: bold;
            margin-top: 10px;
            margin-bottom: 10px;
            color: rgb(229, 233, 196);


            @include responsiveDesktopStandard() {
                font-size: 2rem;
            }

            @include responsiveDesktopMedium() {
                font-size: 1.8rem;
            }

            @include responsiveDesktopSmall() {
                font-size: 1.6rem;
            }

        }

        &_content {
            color: white;

            @include responsiveDesktopStandard() {
                font-size: 1.2rem;
            }

            @include responsiveDesktopMedium() {
                font-size: 1.0rem;
            }

            @include responsiveDesktopSmall() {
                font-size: .8rem;
            }

        }
    }
}

.chosenmobile {
    background-color: $bgcolor_2;
    display: flex;
    height: 0px;
    width: 100%;
    // border:10px solid gold;

    &_show {
        display: flex;
        flex-direction: column;
        transition: height 1s;
        width: 100%;
        height: 520px;

        &>img {
            // display: none;
            transition: height 1.5s;
            height: 300px;
            object-fit: contain;
        }

        &>div {
            // font-size: .75rem;
            transition: height 2s;
            transition-delay: 5s;
        }
    }

    &_close {
        // display: none;
        transition: height 1s;

        &>img {
            height: 0px;
        }

        &>div {
            width: 0px;

            &>div {
                // display: none;
                font-size: 0px !important;
            }
        }
    }
}

.small {
    width: 200px;
    transition: width 2s;
}

.normal {
    width: 270px;
    transition: width 2s;
}

.chosenitem {
    background-color: $bgcolor_2;
    color: white;
}

.mcontainer {
    display: flex;
    flex-direction: column;
    border-top: 2px solid rgba($color: $amanin_logo_color, $alpha: 0.3);
    border-bottom: 2px solid rgba($color: $amanin_logo_color, $alpha: 0.3);
    padding-top: 10px;

    &_top {
        // min-height: 500px;
        // border: 10px solid grey;

        &_c {
            // border:10px solid blue;
        }
    }
}

.mbody3_content {
    // border: 10px solid grey;

    &_title {
        // border:10px solid green;
        // font-size: 1.8rem !important;
        text-align: center;
        font-weight: bold;
    }

    &_content {
        // font-size: 1.4rem !important;
        text-align: center;
    }
}

.mbody3_content2 {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    // border: 10px solid blue;

    &>div {
        display: flex;
        flex-direction: column;
        // font-size: 1.1rem !important;
        padding: 10px;
        align-content: center;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        margin: 5px;
        width: 120px;
        height: 50px;
        text-align: center;


        &:hover {
            cursor: pointer;
        }

        &>img {
            width: 30px;
        }

    }
}

.mb {
    // font-size: 1.2rem !important;
}

.mbt {
    // font-size: 1.2rem;
}

.itemmobile {
    border: 2px solid $bgcolor_2;
    // font-size: 1.5rem !important;
    // width: 200px !important;
    // height: 100px !important;

    @include responsiveMobileStandard() {
        font-size: .9rem;
        height: 80px !important;
        width: 100 !important;
    }

    @include responsiveMobileMedium() {
        font-size: .7rem;
        height: 70px !important;
        width: 90px !important;
    }

    @include responsiveMobileSmall() {
        font-size: .5rem;
        height: 70px !important;
        width: 90px !important;
    }


    &>img {
        // border: 2px solid green;
        margin-right: 5px;
        width: 30px !important;
    }
}