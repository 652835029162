@import "./fonts.scss";

@mixin responsive($min-width, $max-width: null) {
    @if $max-width {
        @media screen and (min-width: $min-width) and (max-width: $max-width) {
            @content;
        }
    }

    @else {
        @media screen and (min-width: $min-width) {
            @content;
        }
    }
}


@mixin responsiveDesktopStandard() {
    @include responsive(1277px) {
        @content;
    }
}

@mixin responsiveDesktopMedium() {
    @include responsive(1022px, 1277px) {
        @content;
    }
}

@mixin responsiveDesktopSmall() {
    @include responsive(767px, 1022px) {
        @content;
    }
}

// diff = 255
@mixin responsiveMobile() {
    @include responsive(0px, 767px) {
        @content;
    }
}

@mixin responsiveMobileStandard() {
    @include responsive(512px, 767px) {
        @content;
    }
}

@mixin responsiveMobileMedium() {
    @include responsive(256px, 512px) {
        @content;
    }
}

@mixin responsiveMobileSmall() {
    @include responsive(0px, 256px) {
        @content;
    }
}

$bgcolor_1: #f7f8fa;
$bgcolor_2: #0592a1;

// $amanin_font_family: 'Quicksand';
// $amanin_font_family: 'OpenSans';
$amanin_font_family: 'Poppins';
// $amanin_font_family: 'Roboto';
// $amanin_font_family: 'Mont';

$amanin_font_size: 0.75rem;
$amanin_cool_color: white;
$amanin_cool_opacity: 0.7;

$amanin_bg_color: rgb(148, 201, 228);
$font_color: rgb(84, 114, 142);

// $amanin_header_color: #423d3d;
$amanin_header_color: #264750;
$amanin_logo_color: #82c9ec;

// based on guideline
$amanin_logo_color2: #019fef;
$amanin_logo_color4: #00e0db;
// end based on guideline

$amanin_logo_color3: rgb(152, 211, 241);
$btn_blue_color: rgb(42, 109, 218);

@keyframes fadeInScale {
    0% {
        opacity: 0;
        scale: 0.9;
    }

    100% {
        opacity: 1;
        scale: 1;
    }
}


@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.btnOk {
    background-color: #0592a1;
    border: 2px solid #0592a1;
    font-size: .85rem;
    padding: 5px;
    border-radius: 5px;
    color: white;

    &:hover {
        cursor: pointer;
        background-color: #0591a176;
        transition: .5s;
        color: rgb(53, 48, 48);

    }
}

.fadeout {
    animation: fadeOut 1s;
}

.fadein {
    animation: fadeIn 2s;
}

.fadein1s {
    animation: fadeIn 1s;
}

.fadeinscale {
    animation: fadeInScale 3s;
}
