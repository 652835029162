@import "../assets/scss/common.scss";

.container {
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: rgb(255, 255, 255);
    display: flex;
    margin-left: 10px;
    align-items: center;
    border-radius: 5px;
    min-width: 70px;
    justify-content: center;
    transition: .5s;
    color: black;
    font-weight: bold;

    &>img {
        width: 30px;
        border-radius: 30px;
        padding: 0px;
        background-color: #00000030;
    }

    &>span {
        margin: 3px;
        font-size: .85rem;
    }

    &:hover {
        border-color: rgb(73, 36, 186);
        cursor: pointer;
    }

    &_modal {
        position: absolute;
        top: 28px;
        right: -1px;
        font-size: .85rem;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-color: rgb(73, 36, 186);

        border-radius: 5px 0px 5px 5px;
        background-color: white;
        transition: .5s;
        z-index: 101;

        &>div {
            display: flex;
            align-items: center;
            white-space: nowrap;
            padding: 5px;
            margin-bottom: 2px;
            margin-top: 2px;
            transition: .5s;

            &>img {
                width: 30px;
                margin-right: 4px;
                background-color: #00000030;
                border-radius: 30px;
            }

            &:hover {
                transition: .5s;
                background-color: rgba(128, 134, 182, 0.332);
                cursor: pointer;
            }
        }
    }
}