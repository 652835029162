@import "../../assets/scss/common.scss";

.container {
    display: flex;
    // flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    background-color: rgba(250, 235, 215, 0.2);
    padding: 10px;
    // border: 10px solid green;

    &>div {
        text-align: center;
        margin: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        // border:10px solid blue;

        &>span {
            font-weight: bold;
            color: #374955;

            @include responsiveDesktopStandard() {
                font-size: 1.4rem;
            }

            @include responsiveDesktopMedium() {
                font-size: 1.2rem;

            }

            @include responsiveDesktopSmall() {
                font-size: 1.0rem;

            }

            @include responsiveMobileStandard() {
                font-size: 1.4rem;
                margin-bottom: 10px;
            }

            @include responsiveMobileMedium() {
                font-size: 1.2rem !important;
                margin-bottom: 10px;
            }

            @include responsiveMobileSmall() {
                font-size: 1.0rem;
                margin-bottom: 10px;
            }
        }

        &>div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 10px;
            background-color: white;
            border-radius: 10px;
            margin-bottom: 10px;
            box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.225);
            width: 250px;
            height: 250px;

            & .v {
                font-size: 2.5rem;
                font-weight: bold;

                @include responsiveDesktopStandard() {
                    font-size: 2.5rem;
                }

                @include responsiveDesktopMedium() {
                    font-size: 2.3rem;

                }

                @include responsiveDesktopSmall() {
                    font-size: 2.1rem;

                }

                @include responsiveMobileStandard() {
                    font-size: 1.9rem;

                }

                @include responsiveMobileMedium() {
                    font-size: 1.7rem;

                }

                @include responsiveMobileSmall() {
                    font-size: 1.5rem;
                }
            }

        }
    }
}

.hexagoncontainer {
    width: 200px;
    /* Sesuaikan dengan lebar hexagon */
    height: 115.47px;
    /* Sesuaikan dengan tinggi hexagon (hitungan matematis untuk hexagon regular) */
    position: relative;
}

.hexagon {
    width: 100%;
    padding-bottom: 115.47%;
    /* Sesuaikan dengan tinggi hexagon (hitungan matematis untuk hexagon regular) */
    background-color: #3498db;
    /* Sesuaikan dengan warna yang diinginkan */
    position: absolute;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);

    &>span {
        color: white;
        border: 2px solid black;
    }
}

.mobile {
    font-size: 1.5rem !important;
}