@import url("../../assets/scss/common.scss");

.container {
    min-height: 400px;
    width: 100%;
    // background-color: rgb(211, 212, 228);
    
    &_content {
        // border:10px solid red;
        max-width: 1360px;
        margin: auto;
        display: flex;
        justify-content: space-evenly;
        flex-direction: row-reverse;

        &_left {
            // color: white;
            display: flex;
            flex-direction: column;
            max-width: 600px;
            
            & .title {
                font-weight: bolder;
                font-size: 1.5rem;
                margin-top: 20px;
                margin-bottom: 20px;    
            }
            & .title2 {
                font-weight: bolder;
                font-size: 1.3rem;
                margin-top: 10px;
                margin-bottom: 10px;
            }

            & .content {
                font-size: 1.2rem;
            }
        }

        &right {

        }
    }
}