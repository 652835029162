.container {
    // border:2px solid red;
    &>div {
        display: flex;
        flex-direction: row;
        // border:2px solid white;
        flex-wrap: wrap;
        
        & .package {
            background-color: rgb(154, 211, 166);
            color: black;
            padding: 10px;
            margin: 10px;
            transition: .5s;
            border-radius: 5px;
            transition: .5s;
            white-space: nowrap;

            &:hover {
                cursor: pointer;
                transition: .5s;
                font-weight: bold;
            }
        }

        & .free {
            background-color: black;
            color: white;
            padding: 10px;
            margin: 10px;
            border-radius: 5px;
            transition: .5s;
            white-space: nowrap;

            &:hover {
                cursor: pointer;
                transition: .5s;
                font-weight: bold;
            }
        }

        & .case {
            background-color: rgb(71, 61, 43);
            color: white;
            padding: 10px;
            margin: 10px;
            border-radius: 5px;
            transition: .5s;
            white-space: nowrap;

            &:hover {
                cursor: pointer;
                transition: .5s;
                font-weight: bold;
            }
        }
    }
}