@import "../../assets/scss/common.scss";

.container {
    // border: 5px solid red;
    margin-top: 67px;
    font-size: .8rem;
    font-family: $amanin_font_family;

    &_content {
        // border:2px solid blue;
        border-radius: 20px;
        max-width: 800px;
        margin: 30px auto;
        display: flex;
        box-shadow: 1px 1px 10px rgba($color: black, $alpha: .5);

        @include responsiveMobile() {
            box-shadow: none;
        }
        &_left {
            background-color: rgba($color: $amanin_logo_color, $alpha: 1.0);
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
        }

        &_right {
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;

            &_c {
                flex-grow: 1;
                // border:2px solid red;
                overflow-y: auto;
                height: calc(100vh - 300px);
                min-height: 300px;
                border-top: 1px solid rgba($color: $amanin_logo_color, $alpha: .5);
                padding: 10px;
                // margin:0px 0px 0px 10px;
            }

            &_b {
                // border:1px solid red;
                background-color: rgba($color: $amanin_logo_color, $alpha: 1.0);
                border-bottom-right-radius: 20px;
                border-bottom-left-radius: 20px;
                display: flex;
                justify-content: flex-end;
                padding: 20px;

                @include responsiveMobile() {
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 0;
                }
                & .btn {
                    padding: 10px;
                    font-weight: bold;
                    transition: .3s;

                    &.inactive {
                        transition: .3s;
                        background-color: rgb(222, 222, 198);
                        color: #000000;
                    }

                    &.active {
                        transition: .3s;
                        background-color: #0953b4;
                        color: white;

                        &:hover {
                            cursor: pointer;
                            background-color: #0f5dc2;
                            // color: rgb(141, 197, 225);
                            color: rgb(192, 218, 29);
                    
                        }
                    }
                }

            }
        }
    }
}

// apply to all paragraphs
.paragraph {
    display: flex;
    flex-direction: column;
    text-align: justify;

    & .break {
        margin: 10px;
    }

    & .title {
        // border:1px solid red;
        text-align: center;
        font-weight: bold;
        font-size: 1.0rem;
    }

    & .date {
        text-align: center;
        font-style: italic;
    }

    & .partitle {
        font-size: 1.0rem;
        word-wrap: wrap;
        margin-top: 10px;
        font-weight: bold;
    }

    &>p {
        text-indent: 27px;
    }
}